/*!
 * Web Fonts from Fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2020 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      Typodermic Fonts Inc.
 * License URL: https://www.fontspring.com/licenses/typodermic/webfont
 *
 *
 */

/* @font-face {
    font-family: 'ws_coolvetica';
    src: url('webfonts/coolvetica_light_macroman/coolvetica_lt-webfont.woff2') format('woff2'),
         url('webfonts/coolvetica_light_macroman/coolvetica_lt-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
} */

@font-face {
  font-family: 'ws_coolvetica';
  src: url('../../fonts/coolvetica/webfonts/coolvetica_regular_macroman/coolvetica_rg-webfont.woff2') format('woff2'),
       url('../../fonts/coolvetica/webfonts/coolvetica_regular_macroman/coolvetica_rg-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ws_coolvetica';
  src: url('../../fonts/coolvetica/webfonts/coolvetica_regular_macroman/coolvetica_rg-webfont.woff2') format('woff2'),
       url('../../fonts/coolvetica/webfonts/coolvetica_regular_macroman/coolvetica_rg-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
    font-family: 'ws_coolvetica';
    src: url('webfonts/coolvetica_bold_macroman/coolvetica_bd-webfont.woff2') format('woff2'),
         url('webfonts/coolvetica_bold_macroman/coolvetica_bd-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
} */
